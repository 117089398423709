import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';
import { useAuthStore } from '@/stores';

export const useUsersStore = defineStore({
    id: 'users',
    state: () => ({
        //users: {},
        baseUrl : `${import.meta.env.VITE_API_URL}/user`        
    }),
    actions: {        
        ...storeDbActions(),

        async loadData(...args) {
            return this.performLoadData(`${this.baseUrl}`,...args);
        },   

        async createUser(data) { //  
            return await fetchWrapper.post(`${this.baseUrl}`, data);
        },

        async getUser(userid) {
            return await fetchWrapper.get(`${this.baseUrl}`);                      
        },

        async updateUser(data) {
            return await fetchWrapper.put(`${this.baseUrl}`, data);
        },

        async removeUser(data) {
            return await fetchWrapper.delete(`${this.baseUrl}`, data); // password
        },

        async validateUser(code) {
            const auth = useAuthStore();
            console.log(auth.loggedIn())
            return await fetchWrapper.post(`${this.baseUrl}/validate`, { code });
        },        

        async getResetPassword(data) {
            return  await fetchWrapper.put(`${this.baseUrl}/resetpassword`, data);
        },
        
        async resetPassword(data) {
            return await fetchWrapper.post(`${this.baseUrl}/resetpassword/`, data);
        },

        async changePassword(data) {
            return await fetchWrapper.put(`${this.baseUrl}/changepassword`, data); //{oldpassword, newpassword}
        },

        async changeEmail(data) {
            return await fetchWrapper.put(`${this.baseUrl}/changeemail`, data); //{email, password}
        },

    }
});
