import { useAuthStore } from '@/stores';
import { defineStore } from 'pinia';
import { fetchWrapper, storeDbActions } from '@/helpers';

export const defaultProgram = {};

["sunday","monday","tuesday","wednesday","thursday","friday","saturday"].forEach(day=>{
    defaultProgram[day] = [{ begin: "", end : ""}, { begin: "", end : ""},{ begin: "", end : ""}];
});

export const useDevicesStore = defineStore("devices", {
    id : "devices",
    state: () => ({
        baseUrl : `${import.meta.env.VITE_API_URL}/device`,
    }),
    actions: {
        ...storeDbActions(),
     
        async getLiveDeviceConnectionState(devicecodes) {
            return await fetchWrapper.put( `${import.meta.env.VITE_API_URL}/GetDevicesConnectionStateAsync`,devicecodes);
        },        

        async getLiveDevice(deviceId) {
            return await fetchWrapper.get(`${this.baseUrl}/${deviceId}/live`);
        },        

        async updateLiveDevice(deviceId, data) {
            return await fetchWrapper.put(`${this.baseUrl}/${deviceId}/live`, data);
        },        

        async unlinkDevice(deviceId) {
            return await fetchWrapper.delete(`${this.baseUrl}/${deviceId}`);
        },

        async checkLinkDevice(deviceId) {
            return await fetchWrapper.get(`${this.baseUrl}/${deviceId}/available`);
        },

        async sendCode(deviceId) {            
            const auth = useAuthStore();            
            return await fetchWrapper.get(`${this.baseUrl}/${deviceId}/`+(auth.loggedIn() ? `sendcode` : `asendcode`));
        },

        async validateCode(deviceId,code) {
            const auth = useAuthStore(); 
            return await fetchWrapper.put(`${this.baseUrl}/${deviceId}/`+(auth.loggedIn() ? `validatecode` : `avalidatecode`), {code});                
        },

        async deleteCode(deviceId) {
            const auth = useAuthStore();    
            return await fetchWrapper.delete(`${this.baseUrl}/${deviceId}/`+(auth.loggedIn() ? `deletecode` : `adeletecode`));
        },

        async checkDeviceExists(code) {
            return await fetchWrapper.get(`${this.baseUrl}/${code}/exists`);
        },

        async linkDevice(deviceId) {
            return await fetchWrapper.post(`${this.baseUrl}/${deviceId}`, { deviceid: deviceId});
        }

    }
});
