import { defineStore } from 'pinia';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useDevicesStore, useAuthStore } from '@/stores';
import { formatTime }  from '@/helpers';

export const useSignalRStore = defineStore("signalr", {
    state: () => {
        return {
            dolog : false,
            connection : null,            
            items : [],
            sequenceData : {},          
            onAfterConnect : null,
            onBeforeUpdate : null,
            onUpdate : null,
            onAfterJoin : null,
            onAfterInitialData : null,

            deviceState : {
                OFFLINE     : 0,
                ONLINE      : 1,
                ONLINE_WITH_ERROR : 2,
                UNKNOWN     : 666,
                TYPE_MISMATCH   : 1969

            }
        }
    },
    getters: {

    },


    actions: {
        async close() {
            if(!this.connection) return;
            try {
                this.log('aaaaaaaaaarrrgggg');

                this.off("iotMessage");
                this.log('after OfMessages');


                await this.leaveAllGroups();
                this.log('afterLeaveGroups');

                
//                await this.connection.stop();
                //this.log('after Stop');

                this.connection=null;
                this.items = [];
            }
            catch(e){

            }
        },

        async connect() {
            if(this.connection!=null){
                this.log("Connection already present");
                
                if(this.onAfterConnect)
                    this.onAfterConnect("");
            }            
            
            else {
                this.log("Connecting");
            
                const headers = { "Authorization": "Bearer " + useAuthStore().user.token }
     
                this.connection = new HubConnectionBuilder()
                    .withUrl(`${import.meta.env.VITE_SIGNALR_URL}`, { headers })
                    .build();
                    

                //window.addEventListener('beforeunload', function (e) {
                /*
                window.onbeforeunload = function() {
                    this.close();
                    return undefined;
                };
                */
                //});

                if(this.connection) await this.connection.start()
                    .then((msg) => {
                        this.log("Started");
                        this.off("iotMessage");
                        this.on("iotMessage", this.onMessage);

                        this.checkJoins();
                    })
                    .catch((err)=>{
                         this.log("CONN START ERROR",err);
                    });
            }
        },


        onMessage(msg) {
            
            try {
                var obj = (typeof msg == "string")?JSON.parse(msg):msg;
                this.log("SIGNALR:",obj);

                if(obj.hasOwnProperty('SEQUENCE_NUMBER')) obj.__IS_ONLINE = true; // MOET WEL 

                obj = this.doSequenceData(obj);                               

                var device = this.itemOnCode(obj.DEVICE_ID);
                if(device){
                    this.log("DATA FOR: "+obj.DEVICE_ID+" "+obj.ENQUEUED_TIME, new Date(), obj);
                    obj.__LAST_UPDATE = Date.now();

                    if(obj.hasOwnProperty("FAULT")) {                        
                        obj.statusid = device.__IS_ONLINE?(obj.FAULT?this.deviceState.ONLINE_WITH_ERROR:this.deviceState.ONLINE):this.deviceState.OFFLINE;
                    }

                    if(obj.hasOwnProperty("BURNER_FAULT")) {                        
                        obj.statusid = device.__IS_ONLINE?(obj.BURNER_FAULT?this.deviceState.ONLINE_WITH_ERROR:this.deviceState.ONLINE):this.deviceState.OFFLINE;
                    }
                    

                    if(obj.hasOwnProperty("CURRENT_TEMPERATURE")) obj.temperature = obj.CURRENT_TEMPERATURE;

                    if(obj.hasOwnProperty("HIGHLOW_OFFSET")||obj.hasOwnProperty("HIGHLOW_AUTO_MODE_ON"))  {
                        if(obj.hasOwnProperty("HIGHLOW_AUTO_MODE_ON")){
                            if(!obj.HIGHLOW_AUTO_MODE_ON) obj.HIGH_LOW_SWITCH_MODE=0;
                        }
                        
                        if(obj.hasOwnProperty("HIGHLOW_OFFSET")){    
                            obj.HIGH_LOW_SWITCH_MODE=obj.HIGHLOW_OFFSET;
                            obj.HIGHLOW_AUTO_MODE_ON=obj.HIGHLOW_OFFSET>0;
                        }
                    }    

                    if(obj.hasOwnProperty("PROGRAM")) {
                        Object.keys(obj.PROGRAM).forEach((day)=>{
                            for(var i=0;i<3;i++){
                                //console.log("onMessage BEFORE",day, i, obj.PROGRAM[day][i].begin, obj.PROGRAM[day][i].end)
                                
                                obj.PROGRAM[day][i].begin = formatTime(obj.PROGRAM[day][i].begin);
                                obj.PROGRAM[day][i].end = formatTime(obj.PROGRAM[day][i].end);

                                if((obj.PROGRAM[day][i].begin==null)&&(obj.PROGRAM[day][i].end!=null)) {
                                    obj.PROGRAM[day][i].begin="00:00";
                                }
                                if((obj.PROGRAM[day][i].begin!=null)&&(obj.PROGRAM[day][i].end==null)) {
                                    obj.PROGRAM[day][i].end="00:00";
                                }



                                //console.log("onMessage AFTER",day, i, obj.PROGRAM[day][i].begin, obj.PROGRAM[day][i].end)

                            }
                        });
                    }

                    
                    if(obj.hasOwnProperty('DEVICE_TYPE') && device.hasOwnProperty('devicetype')) {
                        if(obj.DEVICE_TYPE != device.devicetype) {
                            obj.__IS_ONLINE = false;
                            obj.statusid = this.deviceState.TYPE_MISMATCH;
                        }                        
                    }


                    if(this.onBeforeUpdate) this.onBeforeUpdate(this.filterChanges(device, obj), device);
                    device = Object.assign(device, obj);    

                    

                    if(this.onUpdate) this.onUpdate(device);
                }
                else this.log("UNKNOW DEVICE "+obj.DEVICE_ID);
            }
            catch (error) {
                console.error(error);
            }
        },

        filterChanges(device, obj) {
            var result = {};

            //$.each( obj, function( key, value ) { // This fucks up "this." 
            Object.keys(obj).forEach( key => {                
                var value = obj[key];
                var match = true;                
                if((device.hasOwnProperty(key))&&(key=="PROGRAM")){
                    $.each( value, function( day, program ) {
                        for(var i=0;i<3;i++)
                            match=match && (program[i].begin==device.PROGRAM[day][i].begin) && (program[i].end==device.PROGRAM[day][i].end);
                    });
                }
                else if((!device.hasOwnProperty(key))||(device[key] != value)) 
                    match = false;

                if(!match){ 
                    this.log(key, device[key], ":=" ,value);
                    result[key]=value;
                }
            });
 
            this.log("CHANGES", result); 

            return result;

        },
        

        doSequenceData(obj) {
            const reservedFields = ["DEVICE_ID", "ENQUEUED_TIME","SEQUENCE_NUMBER"];

            if(obj.hasOwnProperty('ENQUEUED_TIME')) {
                
                var newTime = this.formatDateTime(obj.ENQUEUED_TIME);
                Object.keys(obj).forEach( key => {
                    if(reservedFields.indexOf(key)<0) {
                        
                        if(!this.sequenceData.hasOwnProperty(key)) {
                            this.sequenceData[key] = {
                                ENQUEUED_TIME : newTime,
                                SEQUENCE_NUMBER : obj.SEQUENCE_NUMBER
                            }
                        }
                        else {
                            
                            var oldTime = this.sequenceData[key].ENQUEUED_TIME; 
        
                            var bad1 = (newTime<oldTime);     
                            var bad2 = (newTime==oldTime)&&(obj.SEQUENCE_NUMBER<=this.sequenceData[key].SEQUENCE_NUMBER);
                            
                            if(bad1||bad2) {     
                        
                                if(bad1) this.log("IoTMessage: Message out of sync. ("+key+") Discard.",newTime,"<",oldTime);
                                if(bad2) this.log("IoTMessage: Message out of sync. ("+key+") Discard.", obj.SEQUENCE_NUMBER,"<=",this.sequenceData[key].SEQUENCE_NUMBER);                    
                                delete obj[key];
                            }    
                            else {                
                                this.sequenceData[key] = {
                                    ENQUEUED_TIME : newTime,
                                    SEQUENCE_NUMBER : obj.SEQUENCE_NUMBER
                                }     
                            }
                        }                  
                    }     
                });        
            }

            return obj;
        },

        log(...args) {
            if(this.dolog) console.log("SIGNALR: ",...args);
        },

        connected() {
            return (this.connection)&&(this.connection._connectionState== "Connected");
        },

        on(event, func) {
            if(!this.connected()) throw "Not connected (on)";
            return (func)?this.connection.on(event, func):null;
        },
        off(event, func) {
            this.connection.off(event, func);
        },

        invoke(name, data) {
            return this.connection.invoke(name, data);   
        },

        attached() {
            var a=[];
            this.items.forEach((device)=> {
                a.push({
                    id : device.id,
                    code : device.code,

                });
            });

            return a;
        },
        
        requestInitialData(device) {
            return useDevicesStore().getLiveDevice(device.id)
                .then(data => {
                    
                    if(parseInt(data.code)!=200) {
                        this.goOffline(device);
                        if(this.onAfterInitialData) this.onAfterInitialData(device , "api."+data.message);

                    }
                    else {
                        this.goOnline(device);    
                        if(this.onAfterInitialData) this.onAfterInitialData(device, data);
                    }
                })
                .catch(error => {
                    this.goOffline(device);
                    if(this.onAfterInitialData) this.onAfterInitialData(device , "api."+error);
                });    
        },

        itemOnId(id) {
            return this.items.find((item) => item.id == id);
        },
        itemOnCode(code) {
            return this.items.find((item) => item.code == code);
        },


        isOnline(device) {            
            return device?device.__IS_ONLINE:false;
        },
        isOnlineId(id) {
            var device = this.itemOnId(id);
            return device?device.__IS_ONLINE:false;
        },
        isOnlineCode(code) {
            var device = this.itemOnCode(code);
            return device?device.__IS_ONLINE:false;
        },


        goOnline(device){
            if(device) this.onMessage({DEVICE_ID:device.code, __IS_ONLINE : true});
            return device;
        },
        goOffline(device){
            if(device) this.onMessage({DEVICE_ID:device.code,__IS_ONLINE : false, statusid :this.deviceState.OFFLINE});          
            return device;  
        },

        goOnlineId(id) {
            return this.goOnline(this.itemOnId(id));
        },
        goOfflineId(id) {
            return this.goOffline(this.itemOnId(id));
        },

        checkJoins() {
            this.items.forEach((device)=> {
                this.joinGroup(device);
            });
        },
      
        async leaveAllGroups() {
            this.items.forEach((device)=> {
                this.log('Leaving ',device.code);
                if(this.connection && this.connected())
                    this.connection.invoke("LeaveGroup", device.code);
            });
        },

        joinGroup(device, requestData = true) {
            if((!device)||(device.__HAS_JOINED_GROUP===true)||(!this.connected()))return;

            if(this.connection && this.connected())
                this.connection.invoke("JoinGroupAsync", device.code)
                    .then(()=>{
                        this.log("ATTACHED OK ",device.code);
                        this.onMessage({DEVICE_ID:device.code, __HAS_JOINED_GROUP : true});

                        if(requestData) this.requestInitialData(device);
                        if(this.onAfterJoin) this.onAfterJoin(device);
                    })
                    .catch((err) => {
                        this.log("NOT ATTACHED ERROR ",device.code);
                        this.onMessage({DEVICE_ID:device.code, __HAS_JOINED_GROUP : err.toString()});
                        
                        if(this.onAfterJoin) this.onAfterJoin(device);
                    });
        },
            
        attach(devices) {             
            devices.forEach((device)=> {                
                if(!this.itemOnId(device.id)) {
                    var newDevice = Object.assign({}, device);
                    newDevice.__HAS_JOINED_GROUP = false;
                    newDevice.__IS_ONLINE = false;
                    newDevice.__IS_ENABLED = true; 
                    this.items.push(newDevice);

                    this.log("Attach ",device.code);
                    this.joinGroup(newDevice);
                }
                else {
                    this.log("No need to Attach ",device.code);
                    device.__IS_ENABLED = true; 
                }  
            });
        },
 
        formatDateTime(dt) {
            dt = dt.replaceAll("/","-");

            dt = dt.substring(0,dt.indexOf("+")-1);
            //0123456789
            //24-06-2024T08:10:47
            dt = 
                dt.slice(6,10)+"-"+
                dt.slice(3,5)+"-"+
                dt.slice(0,2)+"T"+
                dt.substring(11);

            return new Date(dt
            );
        },
 
    }
});
