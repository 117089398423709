import { defineStore } from 'pinia';
import { fetchWrapper, fillRoutes } from '@/helpers';
import { useAuthStore } from '@/stores';
import { useLocalStorage } from "@vueuse/core"




export const useMenuStore = defineStore("menu", {
    id: "menu",
    state: () => ({
        items: useLocalStorage('menuItems', [])
        
    }),
    actions: {       
        async getAll() {
            const auth = useAuthStore();
            var baseUrl = `${import.meta.env.VITE_API_URL}/`;
            
            baseUrl += (auth.loggedIn() ? `menu` : `amenu`);

            this.items = null;

            var items = await fetchWrapper.get(baseUrl);
            
            if(items.hasOwnProperty("id")) {
                if(items.id<=0) {
                    auth.user = null;
                } 
            }
                        
            if(items.hasOwnProperty("data"))items=items.data;

            for (var i = 0; i < items.length; i++) {
                items[i].children = 0;

                for (var j = 0; j < items.length; j++) {
                    if ((items[j].parentid == items[i].id) && (items[j].enabled)) {
                        items[i].children++;
                        items[j].parent=items[i];
                    }
                }
            }

            for (var i = 0; i < items.length; i++) {
                items[i].routename = null;

                if ((items[i].name != "-") && (items[i].path)) {
                    var name = items[i].name;
                    var path = items[i].path;
                    var parentname = items[i].parent?items[i].parent.name+"/":"";
                    var parentpath = items[i].parent?items[i].parent.path:"";        
 
                    if(parentname) {                                                
                        items[i].path = parentpath+path;
                    }

                    items[i].routename = (items[i].parent?items[i].parent.name+"/":"").toLowerCase()+name;       
        //            items[i].viewpath = String('../views/' + parentname.toLowerCase()+name + 'View.vue').replace("//", "/");                    
                }
            }            


            this.items = items;

            fillRoutes(items);
        }
    }
});

