import { useAuthStore } from '@/stores';
import { router } from '@/helpers';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };     

        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';            
            requestOptions.body = JSON.stringify(body);
        } 

        requestOptions.headers["Access-Control-Allow-Origin"] ="*";

        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.token;
    
    const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);

    if (isApiUrl) {
        if(isLoggedIn)
            return { Authorization: `Bearer ${user.token}` };
        else return [];
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        var data = "";
        try {
            data = text && JSON.parse(text);
        }
        catch(err) {
            return Promise.reject(err);
        }

        if (!response.ok) {
            const { user, logout } = useAuthStore();
            
            

            if((response.type=="cors")&&(response.url.endsWith("/auth"))) {
                // Was from Login request ignore redirect below
            }
            

            else if ([401, 403].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                
                logout();
                router.push("/Login")
                return ;
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}    
